import { ChangeEvent } from "react";

import ToggleItems from "./ToggleItems";

function presentAndUnique(value: string, index: number, self: Array<string>) {
  return value && self.indexOf(value) === index;
}

interface Props {
  state: Catalog.FilterState;
  dispatch: Catalog.FilterDispatch;
  showStatus: boolean;
  strings: Record<string, string>;
}

export default function Filter(props: Props) {
  const { showStatus, state, strings } = props;
  const { lots } = state;

  const allCategories = lots
    .map((l) => l.category)
    .filter(presentAndUnique)
    .sort();
  const allArtists = lots
    .map((l) => l.artist_name)
    .filter(presentAndUnique)
    .sort();

  const handleEstimate =
    (action: "setEstimateMin" | "setEstimateMax") =>
    (evt: ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(evt.target.value.replace(/[^\d]/, ""), 10);
      props.dispatch({ type: action, payload: value });
    };

  const handleToggleAvailable = () => {
    props.dispatch({ type: "toggleAvailable" });
  };

  return (
    <div className="filter">
      {showStatus && (
        <div className="block">
          <h4>{strings.status}</h4>
          <label>
            <input
              type="checkbox"
              onChange={handleToggleAvailable}
              checked={props.state.available}
            />
            {strings.available_for_purchase}
          </label>
        </div>
      )}
      {allCategories.length > 0 && (
        <div className="block">
          <h4>{strings.category}</h4>
          <ToggleItems
            action="toggleCategory"
            dispatch={props.dispatch}
            options={allCategories}
            selected={props.state.categories}
          />
        </div>
      )}
      {allArtists.length > 1 && (
        <div className="block">
          <h4>{strings.artist}</h4>
          <ToggleItems
            action="toggleArtist"
            dispatch={props.dispatch}
            options={allArtists}
            selected={props.state.artists}
          />
        </div>
      )}
      <div className="block">
        <h4>{strings.estimate}</h4>
        <div className="estimate-range">
          <div className="field">
            <label htmlFor="estimateFrom">{strings.from}</label>
            <input
              id="estimateFrom"
              value={state.estimateMin || ""}
              onChange={handleEstimate("setEstimateMin")}
              placeholder={strings.from}
            />
          </div>
          <div className="field">
            <label htmlFor="estimateTo">{strings.to}</label>
            <input
              id="estimateTo"
              value={state.estimateMax || ""}
              onChange={handleEstimate("setEstimateMax")}
              placeholder={strings.to}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
