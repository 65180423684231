type BackendResponse = Record<string, unknown>;

export default function postRequest(
  url: string,
  data,
  csrfToken: string,
  callback: (data: BackendResponse) => void
) {
  const xhr = new XMLHttpRequest();
  xhr.open("POST", url, true);
  xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
  xhr.setRequestHeader("X-CSRF-Token", csrfToken);
  xhr.onload = () => {
    if (xhr.readyState == 4 && xhr.status == 200) {
      const response = JSON.parse(xhr.responseText) as BackendResponse;
      if (callback) {
        callback(response);
      }
    }
  };
  xhr.send(JSON.stringify(data));
}
