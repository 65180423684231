import { useReducer } from "react";

function arrayToggle<T>(array: Array<T>, item: T): Array<T> {
  if (array.indexOf(item) !== -1) {
    return array.filter((i) => i !== item);
  } else {
    return [...array, item];
  }
}

function reducer(state: Catalog.FilterState, action: Catalog.FilterAction) {
  switch (action.type) {
    case "setEstimateMin":
      return { ...state, estimateMin: action.payload || null };
    case "setEstimateMax":
      return { ...state, estimateMax: action.payload || null };
    case "toggleArtist":
      return { ...state, artists: arrayToggle(state.artists, action.payload) };
    case "toggleAvailable":
      return { ...state, available: !state.available };
    case "toggleCategory":
      return {
        ...state,
        categories: arrayToggle(state.categories, action.payload)
      };
    case "updateLots":
      return { ...state, lots: action.payload };
    default:
      return state;
  }
}

export function filterLots(state: Catalog.FilterState) {
  let lots = state.lots;

  if (state.available) {
    lots = lots.filter((l) => !l.ended);
  }

  if (state.artists.length > 0) {
    lots = lots.filter((l) => state.artists.indexOf(l.artist_name) !== -1);
  }

  if (state.categories.length > 0) {
    lots = lots.filter((l) => state.categories.indexOf(l.category) !== -1);
  }

  if (state.estimateMin) {
    lots = lots.filter((l) => l.estimate_min >= state.estimateMin);
  }

  if (state.estimateMax) {
    lots = lots.filter((l) => l.estimate_max <= state.estimateMax);
  }

  return lots;
}

export default function useFilter(
  lots: Catalog.Lot[]
): [Catalog.FilterState, Catalog.FilterDispatch] {
  const initialState: Catalog.FilterState = {
    lots: lots,
    artists: [],
    available: false,
    categories: [],
    estimateMin: null,
    estimateMax: null
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  return [state, dispatch];
}
