type Props = {
  base_url: string;
  lots: Results.Lot[];
  strings: Record<string, string>;
  sold_date: boolean;
};

export default function ResultsList({
  base_url,
  lots,
  strings,
  sold_date
}: Props) {
  const lotUrl = (lot: Results.Lot) => {
    return `${base_url}/${lot.id}`;
  };

  return (
    <table className="lots list">
      <thead>
        <tr>
          <th>
            <b>{strings.artist}</b> <i>{strings.title}</i>
          </th>
          {sold_date && <th>{strings.sold}</th>}
          <th className="number">{strings.estimate}</th>
          <th className="number">{strings.hammer_price}</th>
        </tr>
      </thead>
      <tbody>
        {lots.map((lot) => (
          <tr key={lot.id}>
            <td>
              <a href={lotUrl(lot)} className="name">
                {lot.artist_name && <b>{lot.artist_name}</b>}
                {!lot.artist_name && lot.object_type} <i>{lot.title}</i>
              </a>
            </td>
            {sold_date && <td>{lot.auction_date}</td>}
            <td className="number">{lot.estimate && "NOK " + lot.estimate}</td>
            <td className="hammer-price">
              {lot.sold_price && "NOK " + lot.sold_price}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
