type DateOrString = Date | string;
type Locale = "nb" | "en";

const monthNames = {
  en: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ],
  nb: [
    "januar",
    "februar",
    "mars",
    "april",
    "mai",
    "juni",
    "juli",
    "august",
    "september",
    "oktober",
    "november",
    "desember"
  ]
};

function parseDate(dateOrStr: DateOrString) {
  return typeof dateOrStr == "string" ? new Date(dateOrStr) : dateOrStr;
}

function zeroPad(num: number | string) {
  let s = `${num}`;
  while (s.length < 2) {
    s = "0" + s;
  }
  return s;
}

export function formatTime(locale: Locale, dateOrStr: DateOrString) {
  const date = parseDate(dateOrStr);
  return [zeroPad(date.getHours()), zeroPad(date.getMinutes())].join(":");
}

// 10. desember 2022 kl 15:00 (CET)
// December 10 2022 at 15:00 (CET)
export function formatDateTime(locale: Locale, dateOrStr: DateOrString) {
  const date = parseDate(dateOrStr);

  if (locale === "nb") {
    return (
      `${date.getDate()}. ${monthNames[locale][date.getMonth()]} ` +
      `${date.getFullYear()} kl ${formatTime(locale, date)} CET`
    );
  } else {
    return (
      `${monthNames[locale][date.getMonth()]} ${date.getDate()} ` +
      `${date.getFullYear()} at ${formatTime(locale, date)} CET`
    );
  }
}

export function formatShortDate(locale: Locale, dateOrStr: DateOrString) {
  const date = parseDate(dateOrStr);
  return [
    zeroPad(date.getDate()),
    zeroPad(date.getMonth() + 1),
    date.getFullYear()
  ].join(".");
}

export function formatShortDateTime(locale: Locale, dateOrStr: DateOrString) {
  return (
    `${formatShortDate(locale, dateOrStr)} ` +
    `${formatTime(locale, dateOrStr)}`
  );
}

export function formatShortDateTimePrecise(
  locale: Locale,
  dateOrStr: DateOrString
) {
  const date = parseDate(dateOrStr);
  return (
    `${formatShortDateTime(locale, dateOrStr)}` +
    `:${zeroPad(date.getSeconds())}`
  );
}
