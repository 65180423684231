import Row from "./Row";

interface Props {
  locale: Locale;
  lots: CustomerLot.Lot[];
  strings: CustomerLot.Localizations;
}

export default function Table(props: Props) {
  const { locale, lots, strings } = props;

  return (
    <table className="customer-lots list">
      <thead>
        <tr>
          <th>{strings.header.auction}</th>
          <th>{strings.header.lot}</th>
          <th>{strings.header.status}</th>
          <th>{strings.header.price}</th>
          <th>{strings.header.results}</th>
        </tr>
      </thead>
      <tbody>
        {lots.map((lot) => (
          <Row key={lot.id} locale={locale} lot={lot} strings={strings} />
        ))}
      </tbody>
    </table>
  );
}
