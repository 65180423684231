import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const cookies = document.cookie
      .split(/;\s*/)
      .map((c) => c.split("="))
      .reduce((obj, c) => {
        obj[c[0]] = c[1];
        return obj;
      }, {});
    if (cookies["customer_token"]) {
      this.element.classList.add("customer-session");
    }
  }

  toggleMobileNav(evt: Event) {
    evt.preventDefault();
    this.element.classList.toggle("show-menu");
  }
}
