import { useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import postRequest from "../../lib/postRequest";
import AdvanceBidForm from "./AdvanceBidForm";
import csrfToken from "../../lib/csrfToken";

type Props = {
  onSubmit: () => void;
  registrationData: BidsRegistration.Data;
  updateRegistrationData: React.Dispatch<
    React.SetStateAction<BidsRegistration.Data>
  >;
  strings: BidsRegistration.Localizations;
  url: string;
};

const defaultFormState = {
  bid: ""
};

function formData(state: BidsRegistration.AdvanceBidData) {
  return { advance_bid: state };
}

function validateData(state: Partial<BidsRegistration.AdvanceBidData>) {
  return state.name && state.bid && state.accept_conditions && state.consent;
}

export default function AdvanceBid(props: Props) {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const formStrings = props.strings.advance_bid;

  const [formState, updateFormState] = useState(defaultFormState);
  const [submitted, setSubmitted] = useState(false);

  const combinedState = { ...props.registrationData, ...formState };

  const submitForm = async (evt: React.MouseEvent) => {
    evt.preventDefault();
    const token = await executeRecaptcha("advance_bid");

    postRequest(
      props.url,
      { ...formData(combinedState), new_google_recaptcha_token: token },
      csrfToken(),
      (response) => {
        if (response.status === "ok") {
          updateFormState(defaultFormState);
          setSubmitted(true);
          if (props.onSubmit) {
            props.onSubmit();
          }
        }
      }
    );
  };

  const handleChange =
    (attr: string) => (evt: React.ChangeEvent<HTMLInputElement>) => {
      const target = evt.currentTarget;
      const value = target.type == "checkbox" ? target.checked : target.value;

      if (Object.prototype.hasOwnProperty.call(formState, attr)) {
        updateFormState({ ...formState, [attr]: value });
      } else {
        props.updateRegistrationData({
          ...props.registrationData,
          [attr]: value
        });
      }
    };

  return (
    <div className="advance-bid">
      {submitted && (
        <div
          className="confirmation"
          dangerouslySetInnerHTML={{ __html: formStrings.confirmation_html }}
        />
      )}
      {!submitted && (
        <AdvanceBidForm
          handleChange={handleChange}
          onSubmit={submitForm}
          state={combinedState}
          validateData={validateData}
          strings={props.strings}
        />
      )}
    </div>
  );
}
