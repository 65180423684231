import { useState, useEffect } from "react";
import ResultsList from "./ResultsList";

type Years = { years: string[] };
type LotsByYear = { [key: string]: Results.Lot[] };

type Props = {
  lots: LotsByYear & Years;
  base_url: string;
  strings: Record<string, string>;
};

export default function TopResults({ lots, base_url, strings }: Props) {
  const [filter, setFilter] = useState<string>("all");

  useEffect(() => {
    readStateFromLocation();

    const handlePopState = () => {
      readStateFromLocation();
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const allYearsRange = () => {
    const years = lots.years;
    return `${years[years.length - 1]}–${years[0]}`;
  };

  const readStateFromLocation = () => {
    if (typeof document !== "undefined" && document.location.hash) {
      const matchedFilter = document.location.hash.match(/([\w\d]+)/);
      setFilter(matchedFilter ? matchedFilter[0] : "all");
    } else {
      setFilter("all");
    }
  };

  const updateFilter = (filter: string) => {
    let url = document.location.pathname;
    if (filter !== "all") {
      url += `#${filter}`;
    }
    if (window.history && window.history.pushState) {
      window.history.pushState(null, null, url);
    }
    setFilter(filter);
  };

  const filteredLots = () => {
    return lots[filter];
  };

  const lotItems = filteredLots();

  return (
    <div className="top-results">
      <div className="filter">
        <select
          name="filter-top-results"
          value={filter}
          onChange={(e) => updateFilter(e.target.value)}>
          <option value="all">{allYearsRange()}</option>
          {lots.years.map((y) => (
            <option key={`year-${y}`} value={y}>
              {y}
            </option>
          ))}
        </select>
      </div>
      <ResultsList
        lots={lotItems}
        base_url={base_url}
        sold_date={true}
        strings={strings}
      />
    </div>
  );
}
