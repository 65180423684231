import ViewButton from "./ViewButton";

interface Props {
  auction: Catalog.Auction;
  results_url: string;
  view: string;
  setView: (value: string) => void;
  showFilter: boolean;
  strings: Record<string, string>;
  toggleFilter: (evt: React.MouseEvent) => void;
}

export default function Header(props: Props) {
  const { auction, showFilter, strings, toggleFilter } = props;

  return (
    <div className="header">
      <h3>
        <span>{strings.catalog}</span>
        {!auction.upcoming && (
          <span className="links">
            <a href={props.results_url}>{strings.auction_result}</a>
          </span>
        )}
      </h3>
      <button className="filter-toggle" onClick={toggleFilter}>
        {showFilter ? strings.close_filter : strings.filter}
      </button>
      <div className="view-select">
        <ViewButton
          label="List"
          value="list"
          view={props.view}
          setView={props.setView}
        />
        <ViewButton
          label="Grid"
          value="grid"
          view={props.view}
          setView={props.setView}
        />
      </div>
    </div>
  );
}
