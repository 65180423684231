import { useEffect, useState } from "react";

export default function useBrowser() {
  const [browser, setBrowser] = useState(false);

  useEffect(() => {
    setBrowser(typeof document === "object");
  }, []);

  return browser;
}
