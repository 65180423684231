import Lot from "./Lot";

interface Props {
  auction: Catalog.Auction;
  lots: Catalog.Lot[];
  strings: Record<string, string>;
  locale: Locale;
}

export default function Lots(props: Props) {
  return (
    <div className="lots">
      {props.lots.map((lot) => (
        <Lot
          lot={lot}
          auctionType={props.auction.auction_type}
          status={props.auction.status}
          key={lot.id}
          locale={props.locale}
          strings={props.strings}
        />
      ))}
    </div>
  );
}
