import { useEffect, useState } from "react";

type DateOrString = Date | string;
type TimePart = [number, string];

interface Props {
  locale: Locale;
  time: DateOrString;
}

const localizedStrings = {
  en: {
    minute: "min",
    minutes: "mins",
    hour: "hour",
    hours: "hours",
    day: "day",
    days: "days",
    lessThanOneMinute: "Less than one minute"
  },
  nb: {
    minute: "min",
    minutes: "min",
    hour: "time",
    hours: "timer",
    day: "dag",
    days: "dager",
    lessThanOneMinute: "Mindre enn ett minutt"
  }
};

function localizedString(locale: Locale, key: string) {
  if (locale in localizedStrings && key in localizedStrings[locale]) {
    return localizedStrings[locale][key] as string;
  } else {
    return "";
  }
}

function labelTimeParts(locale: Locale, parts: TimePart[]) {
  return parts
    .map((part: TimePart) => {
      const [num, key] = part;
      let label = localizedString(locale, key);

      if (num === 0) {
        return null;
      } else if (num >= 1) {
        label = localizedString(locale, `${key}s`);
      }

      return `${num} ${label}`;
    })
    .filter((n) => n)
    .join(", ");
}

function formatRelativeTime(locale: Locale, time: DateOrString) {
  const parsedTime = typeof time == "string" ? new Date(time) : time;
  const absDiff = Math.abs(
    Math.ceil((parsedTime.valueOf() - new Date().valueOf()) / 1000)
  );

  //const seconds = absDiff % 60;
  const minutes = Math.floor(absDiff / 60) % 60;
  const hours = Math.floor(absDiff / (60 * 60)) % 24;
  const days = Math.floor(absDiff / (60 * 60 * 24));

  if (days >= 1) {
    return labelTimeParts(locale, [
      [days, "day"],
      [hours, "hour"]
    ]);
  } else if (absDiff >= 60) {
    return labelTimeParts(locale, [
      [hours, "hour"],
      [minutes, "minute"]
    ]);
  } else {
    return localizedString(locale, "lessThanOneMinute");
  }
}

export default function RelativeTime(props: Props) {
  const { locale, time } = props;

  const [formattedTime, setFormattedTime] = useState(
    formatRelativeTime(locale, time)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setFormattedTime(formatRelativeTime(locale, time));
    }, 500);
    return () => clearInterval(interval);
  }, [locale, time]);

  return <span className="relative-time">{formattedTime}</span>;
}
