import { useEffect, useRef, useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import AdvanceBid from "./BidsRegistration/AdvanceBid";
import OnlineBid from "./BidsRegistration/OnlineBid";
import PhoneBid from "./BidsRegistration/PhoneBid";
import TabButton from "./BidsRegistration/TabButton";

interface Props {
  recaptcha_key: string;
  routes: BidsRegistration.Routes;
  strings: BidsRegistration.Localizations;
}

function restoredState(): Partial<BidsRegistration.Data> {
  if (
    typeof window == "undefined" ||
    !window.localStorage ||
    !window.localStorage.bidRegistrationData
  ) {
    return {};
  }
  return JSON.parse(
    window.localStorage.bidRegistrationData as string
  ) as Partial<BidsRegistration.Data>;
}

export default function Registration(props: Props) {
  const strings = props.strings;

  const [tab, setTab] = useState("none");
  const [registrationData, updateRegistrationData] =
    useState<BidsRegistration.Data>({
      name: "",
      email: "",
      phone: "",
      billing_name: "",
      billing_address: "",
      billing_postal_code: "",
      billing_postal_area: "",
      billing_country: "",
      accept_conditions: false,
      consent: false,
      ...restoredState()
    });

  useEffect(() => {
    if (typeof window != "undefined" && window.localStorage) {
      window.localStorage.bidRegistrationData =
        JSON.stringify(registrationData);
    }
  });

  const toggleTab = (name: string) => {
    if (tab === name) {
      setTab("none");
    } else {
      setTab(name);
    }
  };

  const tabButtonProps = { tab: tab, toggleTab: toggleTab };

  const scrollRef = useRef<HTMLDivElement>(null);

  const scrollToTop = () => {
    if (!scrollRef.current || typeof window == "undefined") {
      return;
    }
    const elem = scrollRef.current,
      rect = elem.getBoundingClientRect(),
      bodyRect = document.body.getBoundingClientRect(),
      offset = rect.top - bodyRect.top - 20;

    if (window.scrollY > offset) {
      window.scrollTo(0, offset);
    }
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey={props.recaptcha_key}>
      <div className="bid-registration">
        {strings.intro_html && (
          <div
            className="intro"
            dangerouslySetInnerHTML={{ __html: strings.intro_html }}
          />
        )}
        <div className="tab-buttons" ref={scrollRef}>
          <TabButton
            {...tabButtonProps}
            name="advance-bid"
            label={strings.tabs.advance_bid}
          />
          <TabButton
            {...tabButtonProps}
            name="phone-bid"
            label={strings.tabs.phone_bid}
          />
          <TabButton
            {...tabButtonProps}
            name="online-bid"
            label={strings.tabs.online_bid}
          />
        </div>
        {tab === "advance-bid" && (
          <AdvanceBid
            url={props.routes.advance_bids}
            onSubmit={scrollToTop}
            registrationData={registrationData}
            updateRegistrationData={updateRegistrationData}
            strings={strings}
          />
        )}
        {tab === "phone-bid" && (
          <PhoneBid
            url={props.routes.phone_bids}
            onSubmit={scrollToTop}
            registrationData={registrationData}
            updateRegistrationData={updateRegistrationData}
            strings={strings}
          />
        )}
        {tab === "online-bid" && <OnlineBid strings={strings} />}
      </div>
    </GoogleReCaptchaProvider>
  );
}
