type Props = {
  strings: BidsRegistration.Localizations;
};

export default function OnlineBid(props: Props) {
  const formStrings = props.strings.online_bid;

  return (
    <div className="online-bid">
      <div
        className="intro"
        dangerouslySetInnerHTML={{ __html: formStrings.intro_html }}
      />
    </div>
  );
}
