export default function csrfToken(): string {
  if (typeof document === "object") {
    const elem = document.querySelector("[name=csrf-token]");

    if (!elem) {
      return "";
    }

    return elem.getAttribute("content") || "";
  } else {
    return "";
  }
}
