type Props = {
  lot: Lot.Lot;
  strings: Lot.Localizations;
};

export default function BidStatus(props: Props) {
  const { lot, strings } = props;

  if (!lot.bidder) {
    return "";
  } else if (!lot.ended && lot.winner) {
    return (
      <div className="bid-status winner">
        {strings.online_bids.status.winner} NOK {lot.max_bid}
      </div>
    );
  } else if (lot.ended && lot.winner) {
    return (
      <div className="bid-status winner">{strings.online_bids.status.won}</div>
    );
  } else if (!lot.ended) {
    return (
      <div className="bid-status outbid">
        {strings.online_bids.status.outbid}
      </div>
    );
  } else {
    return <div className="bid-status">{strings.online_bids.status.lost}</div>;
  }
}
