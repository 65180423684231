import Select from "react-select";

type Option = {
  label: string;
  value: string;
};

type Props = {
  name: string;
  label: string;
  state: CustomerAddress.State;
  dispatch: React.Dispatch<CustomerAddress.Action>;
  strings: CustomerAddress.Localizations;
};

function addressLabel(address: CustomerAddress.Address) {
  return address.address.trim().split(/\n/)[0];
}

function formatAddress(address: CustomerAddress.Address) {
  return (
    `${address.address}\n` +
    `${address.postal_code} ${address.postal_area}\n` +
    `${address.country}`
  )
    .split(/\n+/)
    .join("<br />\n");
}

export default function SelectAddress(props: Props) {
  const { name, state, dispatch, label } = props;

  const address = state.addresses.filter(
    (a) => a.handle == state.selected[name]
  )[0];

  const handleChange = (option: Option) => {
    dispatch({ type: "select", payload: { name: name, handle: option.value } });
  };

  const addressOptions = state.addresses.map((a) => {
    return { value: a.handle, label: addressLabel(a) };
  });

  const value = addressOptions.filter(
    (o) => o.value == state.selected[name]
  )[0];

  return (
    <div className="select-address field">
      <label htmlFor={name}>{label}</label>
      <Select
        name={name}
        options={addressOptions}
        onChange={handleChange}
        value={value}
      />
      <div className="address">
        <p dangerouslySetInnerHTML={{ __html: formatAddress(address) }} />
      </div>
    </div>
  );
}
