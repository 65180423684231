import { Fragment } from "react";

type Props = {
  state: CustomerAddress.State;
};

export default function Serialize(props: Props) {
  const { addresses, deleted, selected } = props.state;

  const order = [...addresses, ...deleted].map((a) => a.handle);

  const name = (address: CustomerAddress.Address, property: string) => {
    const position = order.indexOf(address.handle) + 1;
    return `customer[addresses_attributes][${position}][${property}]`;
  };

  return (
    <Fragment>
      {addresses.map((a) => (
        <Fragment key={a.handle}>
          <input name={name(a, "id")} type="hidden" value={a.id} />
          <input name={name(a, "address")} type="hidden" value={a.address} />
          <input
            name={name(a, "postal_code")}
            type="hidden"
            value={a.postal_code}
          />
          <input
            name={name(a, "postal_area")}
            type="hidden"
            value={a.postal_area}
          />
          <input name={name(a, "country")} type="hidden" value={a.country} />
          <input
            name={name(a, "primary")}
            type="hidden"
            value={selected.primary == a.handle ? "true" : ""}
          />
          <input
            name={name(a, "billing")}
            type="hidden"
            value={selected.billing == a.handle ? "true" : ""}
          />
          <input
            name={name(a, "shipping")}
            type="hidden"
            value={selected.shipping == a.handle ? "true" : ""}
          />
        </Fragment>
      ))}
      {deleted.map((a) => (
        <Fragment key={a.handle}>
          <input name={name(a, "id")} type="hidden" value={a.id} />
          <input name={name(a, "_destroy")} type="hidden" value="true" />
        </Fragment>
      ))}
    </Fragment>
  );
}
