import { useCallback, useEffect, useState } from "react";

type JSONResponse = {
  data: Lot.Lot;
};

async function fetchLot(
  baseUrl: string,
  customerToken: string,
  callback: (lot: Lot.Lot) => void
) {
  let url = baseUrl;
  if (customerToken) {
    url = `${url}?customerToken=${customerToken}`;
  }

  await fetch(url)
    .then((response) => response.json())
    .then((json: JSONResponse) => callback(json.data));
}

function refreshInterval(lot: Lot.Lot, customerToken?: string) {
  const timeLeft =
    (new Date(lot.ends_at).valueOf() - new Date().valueOf()) / 1000;

  let interval = 5000;

  if (timeLeft > 60 * 60) {
    interval = 60000;
  } else if (timeLeft > 5 * 60) {
    interval = 20000;
  }

  if (!customerToken) {
    interval = interval * 4;
  }

  if (typeof document === "object" && document.visibilityState == "hidden") {
    interval = interval * 4;
  }

  return interval;
}

function shouldRefresh(lot: Lot.Lot) {
  return !lot.ended && lot.auction.auction_type == "online_auction";
}

export default function useLot(
  initialLot: Lot.Lot,
  url: string,
  customerToken: string
): Lot.Lot {
  const [lot, setLot] = useState(initialLot);
  const [lastRefresh, setLastRefresh] = useState(new Date());

  const refreshLot = useCallback(() => {
    void fetchLot(url, customerToken, (updatedLot: Lot.Lot) => {
      setLot(updatedLot);
      setLastRefresh(new Date());
    });
  }, [url, customerToken]);

  useEffect(() => {
    let refreshTimer: ReturnType<typeof setTimeout> = null;
    const refreshLoop = () => {
      if (shouldRefresh(lot)) {
        refreshTimer = setTimeout(
          () => {
            refreshLot();
            refreshLoop();
          },
          refreshInterval(lot, customerToken)
        );
      }
    };
    refreshLoop();
    return () => clearTimeout(refreshTimer);
  }, [customerToken, lot, refreshLot]);

  useEffect(() => {
    function throttle(callback: () => void) {
      const now = new Date();
      const elapsed = now.valueOf() - lastRefresh.valueOf();
      if (elapsed >= 10000) {
        callback();
        setLastRefresh(now);
      }
    }

    if (typeof document === "object") {
      const handleChange = () => {
        if (document.visibilityState == "visible" && shouldRefresh(lot)) {
          throttle(() => refreshLot());
        }
      };
      document.addEventListener("visibilitychange", handleChange);
      return () =>
        document.removeEventListener("visibilitychange", handleChange);
    }
  }, [lot, refreshLot, lastRefresh]);

  return lot;
}
