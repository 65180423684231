import { useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import postRequest from "../../lib/postRequest";
import PhoneBidForm from "./PhoneBidForm";
import csrfToken from "../../lib/csrfToken";

type Props = {
  onSubmit: () => void;
  registrationData: BidsRegistration.Data;
  updateRegistrationData: React.Dispatch<
    React.SetStateAction<BidsRegistration.Data>
  >;
  strings: BidsRegistration.Localizations;
  url: string;
};

function formData(state: BidsRegistration.Data) {
  return { phone_bid_registration: state };
}

function validateData(state: Partial<BidsRegistration.Data>) {
  return state.name && state.phone && state.accept_conditions && state.consent;
}

export default function PhoneBid(props: Props) {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const formStrings = props.strings.phone_bid;
  const [submitted, setSubmitted] = useState(false);

  const submitForm = async (evt: React.MouseEvent) => {
    evt.preventDefault();

    const token = await executeRecaptcha("phone_bid");

    postRequest(
      props.url,
      {
        ...formData(props.registrationData),
        new_google_recaptcha_token: token
      },
      csrfToken(),
      (response) => {
        if (response.status === "ok") {
          setSubmitted(true);
          if (props.onSubmit) {
            props.onSubmit();
          }
        }
      }
    );
  };

  const handleChange =
    (attr: string) => (evt: React.ChangeEvent<HTMLInputElement>) => {
      const target = evt.currentTarget;
      const value = target.type == "checkbox" ? target.checked : target.value;
      props.updateRegistrationData({
        ...props.registrationData,
        [attr]: value
      });
    };

  return (
    <div className="phone-bid">
      {submitted && (
        <div
          className="confirmation"
          dangerouslySetInnerHTML={{ __html: formStrings.confirmation_html }}
        />
      )}
      {!submitted && (
        <PhoneBidForm
          handleChange={handleChange}
          onSubmit={submitForm}
          state={props.registrationData}
          validateData={validateData}
          strings={props.strings}
        />
      )}
    </div>
  );
}
