type Props = {
  lot: Lot.Lot;
  strings: Lot.Localizations;
};

export default function SoldPrice(props: Props) {
  const { lot, strings } = props;
  const { auction } = lot;

  const soldPrice = () => {
    if (lot.sold_price) {
      return lot.sold_price;
    } else if (
      auction.auction_type == "online_auction" &&
      lot.ended &&
      lot.online_bids
    ) {
      return lot.current_price;
    }
    return null;
  };

  const showUnsold = () => {
    if (auction.auction_type == "online_auction") {
      return lot.ended && !lot.online_bids;
    } else if (auction.auction_type == "auction") {
      return (
        !lot.sold &&
        (auction.status == "finished" || auction.status == "closed")
      );
    }
    return false;
  };

  if (soldPrice()) {
    return (
      <div className="sold">
        {strings.sold_for} NOK {soldPrice()}
        {lot.sold_reservation && <span> {strings.to_be_approved}</span>}
      </div>
    );
  } else if (showUnsold()) {
    return <div className="sold">{strings.unsold}</div>;
  } else {
    return "";
  }
}
