import SelectAddress from "./SelectAddress";

type Props = {
  state: CustomerAddress.State;
  dispatch: React.Dispatch<CustomerAddress.Action>;
  strings: CustomerAddress.Localizations;
};

export default function SelectAddresses(props: Props) {
  const { strings } = props;

  return (
    <fieldset className="select-addresses">
      <SelectAddress
        name="primary"
        label={strings.primary_address}
        {...props}
      />
      <SelectAddress
        name="billing"
        label={strings.billing_address}
        {...props}
      />
      <SelectAddress
        name="shipping"
        label={strings.shipping_address}
        {...props}
      />
    </fieldset>
  );
}
