import { formatShortDateTimePrecise } from "../../lib/dates";

type Props = {
  locale: Locale;
  lot: Lot.Lot;
  strings: Lot.Localizations;
};

export default function BidLog(props: Props) {
  const { lot, strings } = props;

  const bids = lot.bid_log;

  if (bids.length > 0) {
    return (
      <div className="bid-log">
        <table>
          <thead>
            <tr>
              <th>{strings.online_bids.bidder}</th>
              <th>{strings.online_bids.time}</th>
              <th>{strings.online_bids.amount}</th>
            </tr>
          </thead>
          <tbody>
            {bids.map((bid) => (
              <tr key={bids.indexOf(bid)}>
                <td>{bid.name}</td>
                <td>{formatShortDateTimePrecise(props.locale, bid.time)}</td>
                <td>NOK {bid.bid}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  } else {
    return (
      <div className="bid-log">
        <p>{strings.online_bids.no_bids}</p>
      </div>
    );
  }
}
