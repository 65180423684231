// Entry point for the build script in your package.json

// Rails
import Rails from "@rails/ujs";
Rails.start();

// Stimulus
import { Application } from "stimulus";
import HeaderController from "./controllers/HeaderController";
const application = Application.start();
application.register("header", HeaderController);

// Responsive embeds
import responsiveEmbeds from "./lib/responsiveEmbeds";
responsiveEmbeds();

// React
import { FC } from "react";
import * as Components from "./components";
import ReactRailsUJS from "react_ujs";
ReactRailsUJS.getConstructor = (className: string) =>
  Components[className] as FC;
