import { useRef, useState } from "react";

import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import type { Swiper as SwiperObject } from "swiper";

import Image from "./Image";

type Props = {
  images: Lot.Image[];
  strings: Lot.Localizations;
  title: string;
};

export default function Images(props: Props) {
  const { images } = props;
  const [image, setImage] = useState(props.images ? props.images[0] : null);
  const swiperRef = useRef<SwiperObject>();

  const swipeTo = (image: Lot.Image) => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(images.indexOf(image));
    }
  };

  if (!images || images.length === 0) {
    return (
      <div className="lot-images">
        <div className="image">
          <div className="fallback" />
        </div>
      </div>
    );
  } else if (images.length > 1) {
    return (
      <div className="lot-images">
        <Swiper
          modules={[Navigation]}
          navigation
          slidesPerView={1}
          onSwiper={(s) => (swiperRef.current = s)}
          onSlideChange={(s) => setImage(images[s.activeIndex])}>
          {images.map((img) => (
            <SwiperSlide key={img.id}>
              <Image image={img} {...props} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="slider-pagination">
          {images.map((img) => {
            return (
              <button
                key={img.id}
                onClick={() => swipeTo(img)}
                className={img == image ? "current" : ""}>
                <img src={img.small} />
              </button>
            );
          })}
        </div>
      </div>
    );
  } else {
    return (
      <div className="lot-images">
        <Image image={image} {...props} />
      </div>
    );
  }
}
