import Table from "./CustomerLots/Table";

interface Props {
  locale: Locale;
  lots: CustomerLot.Lot[];
  strings: CustomerLot.Localizations;
}

export default function CustomerLots(props: Props) {
  const { locale, lots, strings } = props;

  const activeLots = lots.filter((l) => !l.ended);
  const endedLots = lots.filter((l) => l.ended);

  return (
    <div className="customer-lots">
      {activeLots.length > 0 && (
        <div className="content-block">
          <h3>{strings.active_bids}</h3>
          <Table locale={locale} lots={activeLots} strings={strings} />
        </div>
      )}
      {endedLots.length > 0 && (
        <div className="content-block">
          <h3>{strings.bid_history}</h3>
          <Table locale={locale} lots={endedLots} strings={strings} />
        </div>
      )}
    </div>
  );
}
